import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { TitledCard } from "./TitledCard";

interface OutputTypeSelectorProps {
  selectedOutputOption: string;
  setSelectedOutputOption: React.Dispatch<React.SetStateAction<string>>;
  selectedIncludeOriginal: boolean;
  setSelectedIncludeOriginal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OutputTypeSelector: React.FC<OutputTypeSelectorProps> = ({
  selectedOutputOption,
  setSelectedOutputOption,
  selectedIncludeOriginal,
  setSelectedIncludeOriginal,
}) => {
  return (
    <TitledCard
      className="px-4 pb-4"
      title={"Select key fields"}
      infoText={"Optional"}
    >
      {/* Buttons for Output Selection */}
      <div className="mt-3 flex flex-row justify-between">
        <div className="flex flex-col items-start gap-2">
          {/* Key Fields Label */}
          <h6 className="flex items-center text-lg font-medium">
            Key Fields
            <span className="relative ml-2 group">
              <FaQuestionCircle size={14} className="text-gray-400" />
              <span className="absolute left-1/2 bottom-full hidden w-48 -translate-x-1/2 rounded bg-black text-white text-xs p-2 group-hover:block">
                Choose whether you want the output to only show the records that
                were different between the two files, or all records, including
                those that matched.
              </span>
            </span>
          </h6>
          {/* Key Fields Button Group */}
          <div className="flex space-x-2">
            <button
              id="output-type-all-records"
              type="button"
              className={`px-4 py-2 border rounded ${
                selectedOutputOption === "allRecords"
                  ? "bg-primary-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => setSelectedOutputOption("allRecords")}
            >
              All Records
            </button>
            <button
              id="output-type-only-differences"
              type="button"
              className={`px-4 py-2 border rounded ${
                selectedOutputOption === "onlyDifferences"
                  ? "bg-primary-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => setSelectedOutputOption("onlyDifferences")}
            >
              Only Differences
            </button>
          </div>
        </div>

        <div className="flex flex-col items-start gap-2">
          {/* Original Data Sheets Label */}
          <h6 className="flex items-center text-lg font-medium">
            Original Data Sheets
            <span className="relative ml-2 group">
              <FaQuestionCircle size={14} className="text-gray-400" />
              <span className="absolute left-1/2 bottom-full hidden w-48 -translate-x-1/2 rounded bg-black text-white text-xs p-2 group-hover:block">
                Choose whether you want the output file to include sheets
                showing the original data from the first and second files that
                you loaded.
              </span>
            </span>
          </h6>
          {/* Original Data Sheets Button Group */}
          <div className="flex space-x-2">
            <button
              id="include-original-include"
              type="button"
              className={`px-4 py-2 border rounded ${
                selectedIncludeOriginal
                  ? "bg-primary-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => setSelectedIncludeOriginal(true)}
            >
              Include
            </button>
            <button
              id="include-original-exclude"
              type="button"
              className={`px-4 py-2 border rounded ${
                !selectedIncludeOriginal
                  ? "bg-primary-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => setSelectedIncludeOriginal(false)}
            >
              Exclude
            </button>
          </div>
        </div>
      </div>
    </TitledCard>
  );
};

export default OutputTypeSelector;
