import { FC, PropsWithChildren } from "react";
import QuestionMark from "../assets/question-mark.svg";
import { Text } from "./Text";
import { Column } from "./Column";
import { Row } from "./Row";

export type TitledCardProps = PropsWithChildren<{
  title: string;
  infoText?: string;
  className?: string;
}>;

export const TitledCard: FC<TitledCardProps> = ({
  children,
  title,
  infoText,
  className,
}) => {
  return (
    <Column className={`border rounded-2xl border-gray-300 shadow-md`}>
      <Row className="items-center bg-gray-200 rounded-t-2xl px-7 py-4 gap-2">
        <Text className="font-semibold">{title}</Text>
        {infoText && <img src={QuestionMark} alt="info" />}
      </Row>

      <div className={className}>{children}</div>
    </Column>
  );
};
