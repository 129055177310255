import { apiUrl } from "../utils/APIUtils";
import { ComparePayload } from "../models/compare";
import { useState } from "react";

export const useCompareMutation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const trigger = async (payload: ComparePayload) => {
    try {
      setIsLoading(true);

      const response = await fetch(`${apiUrl}/compare`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/vnd.ms-excel",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Failed to process the files");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "result.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return {
    isLoading,
    error,
    trigger,
  };
};
