import React, { useState } from "react";
import { Chip } from "@mui/material";
import { FaQuestionCircle } from "react-icons/fa";
import { TitledCard } from "./TitledCard";

interface KeyFieldSelectorProps {
  keyFields: string[];
  selectedKeyFields: string[];
  setSelectedKeyFields: React.Dispatch<React.SetStateAction<string[]>>;
}

const KeyFieldSelector: React.FC<KeyFieldSelectorProps> = ({
  keyFields,
  selectedKeyFields,
  setSelectedKeyFields,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSelect = (item: string) => {
    setSelectedKeyFields((prev: string[]) => {
      if (prev.includes(item)) {
        return prev.filter((i: string) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleClearSelected = () => {
    setSelectedKeyFields([]);
  };

  const filteredItems = keyFields.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    // <div
    //   className="key-field-selector p-3 mt-4 rounded-lg border border-gray-300 bg-gray-50 shadow-lg"
    //   style={{ maxWidth: "100%" }}
    // >
    <TitledCard
      title={"Select key fields"}
      infoText={
        "Select a column that has a different value for each row, and which can be used to match rows between the first and second files. If you select more than one column, all of the columns selectedwill be used in combination as a unique value for matching."
      }
    >
      {/* Content Section: Search, Key Fields, and Selected Items */}
      <div className="flex p-5">
        {/* Left Section: Search and Key Fields */}
        <div className="w-1/2 mr-4">
          {/* Search Field */}
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 mb-2 border border-gray-300 rounded"
          />
          {/* List of Key Fields */}
          <div className="max-h-64 overflow-y-auto border border-gray-300 rounded">
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center px-3 py-2 border-b border-gray-200"
                >
                  <input
                    type="checkbox"
                    id={`checkbox-${index}`}
                    className="mr-2"
                    checked={selectedKeyFields.includes(item)}
                    onChange={() => handleSelect(item)}
                  />
                  {item}
                </div>
              ))
            ) : (
              <div className="px-3 py-2">No results found</div>
            )}
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="w-px bg-gray-300 h-full mx-5"></div>

        {/* Right Section: Selected Items */}
        <div className="w-1/2">
          <h6 className="font-semibold">Selected</h6>
          <div className="mb-2 flex flex-wrap gap-2">
            {selectedKeyFields.length > 0 ? (
              selectedKeyFields.map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  onDelete={() => handleSelect(item)}
                  className="mr-1 mb-1"
                />
              ))
            ) : (
              <p>No fields selected</p>
            )}
          </div>
          {/* Clear Selected Button */}
          <button
            onClick={handleClearSelected}
            disabled={selectedKeyFields.length === 0}
            className="px-4 py-2 border border-red-500 text-red-500 rounded disabled:opacity-50"
          >
            Clear selected
          </button>
        </div>
      </div>
    </TitledCard>
  );
};

export default KeyFieldSelector;
