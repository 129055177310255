import { FC } from "react";
import { Text } from "./Text";

export type ButtonProps = {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  text: string;
};

export type ButtonVariant = "primary" | "success" | "danger" | "warning";

export const Button: FC<ButtonProps> = ({
  className,
  onClick,
  disabled = false,
  text,
}) => {
  return (
    <button
      className={`${className} bg-primary-500 disabled:bg-gray-200 text-white disabled:text-gray-400 hover:bg-primary-600 w-72 p-4 rounded-full transition-opacity`}
      type={"button"}
      onClick={onClick}
      disabled={disabled}
    >
      <Text>{text}</Text>
    </button>
  );
};
