import { FC, PropsWithChildren } from "react";

export type CardProps = PropsWithChildren<{
  className?: string;
}>;

export const Card: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`${className} py-4 px-5 rounded-2xl bg-gray-100`}>
      {children}
    </div>
  );
  // <div className="p-4 border rounded-2xl border-gray-400 shadow-md">
  //   {children}
  // </div>
};
