import React, { FC, useRef, useState } from "react";
import {
  FaExclamationCircle,
  FaFileCsv,
  FaQuestionCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { FiUploadCloud } from "react-icons/fi";
import Papa from "papaparse";

export type FileUploadProps = {
  setFile1Uploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFile1: React.Dispatch<React.SetStateAction<File | null>>;
  file1: File | null;
};

export const FileUpload: FC<FileUploadProps> = ({
  setFile1Uploaded,
  setFile1,
  file1,
}) => {
  const fileInputRef1 = useRef<HTMLInputElement>(null);
  const [parsedFile1, setParsedFile1] = useState<any[]>([]);
  const [error1, setError1] = useState(false);

  const parseCSV = (
    file: File,
    setParsedData: React.Dispatch<React.SetStateAction<any[]>>,
  ) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          console.error("Error parsing CSV:", results.errors);
        }
        setParsedData(results.data);
      },
    });
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: (file: File | null) => void,
    setParsedFile: React.Dispatch<React.SetStateAction<any[]>>,
    setFileUploaded: (value: boolean) => void,
    setError: (value: boolean) => void,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileType = file.name.split(".").pop()?.toLowerCase();
      if (fileType === "csv") {
        setFile(file);
        setFileUploaded(true);
        setError(false);
        parseCSV(file, setParsedFile);

        event.target
          .closest(".file-upload-box")
          ?.classList.add("file-upload-success");
      } else {
        setFile(null);
        setFileUploaded(false);
        setError(true);
      }
    }
  };

  const handleBoxClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleRemoveFile = (
    setFile: (file: File | null) => void,
    setFileUploaded: (value: boolean) => void,
    setError: (value: boolean) => void,
  ) => {
    setFile(null);
    setFileUploaded(false);
    setError(false);
  };

  return (
    <div className="flex flex-col items-center flex-1">
      <div
        className={`file-upload-box flex flex-col justify-center items-center ${
          error1
            ? "border-red-500"
            : file1
              ? "border-cyan-600"
              : "border-gray-300"
        } bg-gray-100 border-2 border-dashed rounded-lg p-5 text-center w-full h-[180px] cursor-pointer transition-all duration-300 hover:border-blue-500 hover:shadow-md`}
        onClick={() => handleBoxClick(fileInputRef1)}
      >
        {file1 ? (
          <div className="flex items-center justify-center">
            <FaFileCsv size={24} color="#00a6ce" />
            <span className="text-gray-500 ml-2">{file1.name}</span>
            <FaTimesCircle
              size={20}
              color="#007bff"
              className="ml-2 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveFile(setFile1, setFile1Uploaded, setError1);
              }}
            />
          </div>
        ) : (
          <>
            <FiUploadCloud size={40} color="#6c757d" />
            <p className="flex flex-row items-center mt-2 text-gray-500">
              Drop first document here or click to browse
              <span className="ml-2 relative">
                <FaQuestionCircle size={16} color="#ced4da" className="ml-1" />
                <span className="absolute z-10 hidden bg-gray-600 text-white p-2 rounded-lg text-xs shadow-md bottom-full left-1/2 -translate-x-1/2 transform opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  Select a comma delimited file to begin your Compare
                </span>
              </span>
            </p>
          </>
        )}
        <input
          ref={fileInputRef1}
          type="file"
          className="hidden"
          onChange={(event) =>
            handleFileSelect(
              event,
              setFile1,
              setParsedFile1,
              setFile1Uploaded,
              setError1,
            )
          }
        />
      </div>
      {error1 && (
        <div className="flex items-center justify-center mt-2 text-red-500">
          <FaExclamationCircle size={16} />
          <span className="ml-2">
            Type of file not supported. Please select a CSV file.
          </span>
        </div>
      )}
    </div>
  );
};
