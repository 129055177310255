import React, { FC, useState } from "react";
import { Header } from "./components/Header";
import { Card } from "./components/Card";
import FileArrow from "./assets/file-arrow.svg";
import { Text } from "./components/Text";
import { Column } from "./components/Column";
import { Row } from "./components/Row";
import { FileUpload } from "./components/FileUpload";
import KeyFieldSelector from "./components/KeyFieldSelector";
import OutputTypeSelector from "./components/OutputTypeSelector";
import { useCompareMutation } from "./hooks/useCompareMutation";
import { extractHeadersFromCSV } from "./utils/extractHeaders";
import { ComparePayload } from "./models/compare";
import { Button } from "./components/Button";

export const App: FC = () => {
  const [file1Uploaded, setFile1Uploaded] = useState<boolean>(false);
  const [file2Uploaded, setFile2Uploaded] = useState<boolean>(false);
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [showKeyFieldSelector, setShowKeyFieldSelector] =
    useState<boolean>(false);
  const [keyFields, setKeyFields] = useState<string[]>([]);
  const [selectedKeyFields, setSelectedKeyFields] = useState<string[]>([]);
  const [showOutputTypeSelector, setShowOutputTypeSelector] =
    useState<boolean>(false);
  const [selectedOutputOption, setSelectedOutputOption] =
    useState<string>("allRecords");
  const [selectedIncludeOriginal, setSelectedIncludeOriginal] =
    useState<boolean>(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const { trigger: callCompare } = useCompareMutation();

  const handleStartClick = async () => {
    if (file1 && file2) {
      const headers1 = await extractHeadersFromCSV(file1);
      const headers2 = await extractHeadersFromCSV(file2);

      setKeyFields(Array.from(new Set([...headers1, ...headers2])));
      setShowKeyFieldSelector(true);
    }
  };

  const handleProceedClick = () => {
    setShowOutputTypeSelector(true);
  };

  const handleSubmit = async () => {
    setSubmitDisabled(true);

    try {
      if (!file1 || !file2) {
        throw new Error("Both files must be uploaded.");
      }

      const file1Content = await file1.text();
      const file2Content = await file2.text();

      const payload: ComparePayload = {
        input1: {
          dataType: "csvData",
          name: file1.name,
          content: file1Content,
        },
        input2: {
          dataType: "csvData",
          name: file2.name,
          content: file2Content,
        },
        fields: Object.fromEntries(
          keyFields.map((field) => [
            field,
            { isKey: selectedKeyFields.includes(field) },
          ]),
        ),
        respondAs: {
          responseType: "respondsAsExcel",
          mimeType: "application/vnd.msexcel",
          fileName: "result.xlsx",
          allRecords: selectedOutputOption === "allRecords",
          includeInputDataInOutput: selectedIncludeOriginal,
        },
      };

      await callCompare(payload);
    } catch (error) {
      console.error("Error during compare:", error);
    }
  };

  const handleStartNewCompare = () => {
    window.location.reload();
  };

  return (
    <Column className="items-center gap-7 bg-white min-h-screen pb-8">
      <Header />
      <Column className="gap-7 max-w-screen-2xl px-4 w-full">
        <Card className="flex flex-col gap-2">
          <Row className="gap-3">
            <img src={FileArrow} alt="File Arrow Icon" />
            <Text className="font-semibold text-black text-lg">
              Data Records Comparison System
            </Text>
          </Row>
          <Text className="font-normal text-black text-base">
            Dispatch Integration’s Compare is an automated, secure desktop app
            that quickly compares .csv and comma-delimited files to find dataset
            differences, saving hours of manual work.
          </Text>
        </Card>

        <Row className="justify-center mt-4 gap-5">
          <FileUpload
            setFile1Uploaded={setFile1Uploaded}
            setFile1={setFile1}
            file1={file1}
          />
          <FileUpload
            setFile1Uploaded={setFile2Uploaded}
            setFile1={setFile2}
            file1={file2}
          />
        </Row>

        {!showKeyFieldSelector && (
          <Button
            onClick={handleStartClick}
            disabled={!file1Uploaded || !file2Uploaded}
            className={"self-center mt-32"}
            text="Start"
          />
        )}

        {showKeyFieldSelector && (
          <KeyFieldSelector
            keyFields={keyFields}
            selectedKeyFields={selectedKeyFields}
            setSelectedKeyFields={setSelectedKeyFields}
          />
        )}

        {showOutputTypeSelector && (
          <OutputTypeSelector
            selectedOutputOption={selectedOutputOption}
            setSelectedOutputOption={setSelectedOutputOption}
            selectedIncludeOriginal={selectedIncludeOriginal}
            setSelectedIncludeOriginal={setSelectedIncludeOriginal}
          />
        )}

        {(showKeyFieldSelector || showOutputTypeSelector) && (
          <Row className="justify-center items-center mt-32 gap-4">
            <Button
              onClick={
                showOutputTypeSelector ? handleSubmit : handleProceedClick
              }
              disabled={isSubmitDisabled}
              text={showOutputTypeSelector ? "Start" : "Proceed"}
            />

            {showOutputTypeSelector && (
              <Button
                onClick={handleStartNewCompare}
                text="Start New Compare"
              />
            )}
          </Row>
        )}

        {showOutputTypeSelector && (
          <div className="text-center mt-4">
            <p className="text-gray-600">
              By proceeding, you agree to our{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-500 hover:underline"
              >
                Terms and Conditions
              </a>{" "}
              and acknowledge reading the{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-500 hover:underline"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        )}
      </Column>
    </Column>
  );
};
