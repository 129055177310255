import { FC } from "react";

export type TextProps = {
  children?: string;
  className?: string;
};

export const Text: FC<TextProps> = ({ children, className }) => {
  return <span className={`${className} font-default`}>{children}</span>;
};
