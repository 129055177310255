import { FC } from "react";
import logo from "../assets/logo.svg";
import { Row } from "./Row";

export type HeaderProps = {};

export const Header: FC<HeaderProps> = () => {
  return (
    <Row className="justify-evenly self-stretch bg-gray-50 p-4">
      <Row className="max-w-screen-xl items-start justify-between w-full gap-4">
        <img className="w-48" src={logo} alt="logo" />
      </Row>
    </Row>
  );
};
